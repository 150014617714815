{
  "dirty": true,
  "raw": "v1.75.0-33-g283d20d78-dirty",
  "hash": "g283d20d78",
  "distance": 33,
  "tag": "v1.75.0",
  "semver": {
    "options": {
      "loose": false,
      "includePrerelease": false
    },
    "loose": false,
    "raw": "v1.75.0",
    "major": 1,
    "minor": 75,
    "patch": 0,
    "prerelease": [],
    "build": [],
    "version": "1.75.0"
  },
  "suffix": "33-g283d20d78-dirty",
  "semverString": "1.75.0+33.g283d20d78.dirty"
}