import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  AuthenticationApiService,
  PatchToursDto,
  PermissionsEnum,
  UserBasicInfoDto,
  UserBasicInfoResponseDto,
  UserInformationApiService,
} from '@ev-portals/cp/frontend/shared/api-client';
import {
  AnalyticsService,
  CookieService,
  FeedbackMessageService,
  GlobalDataService,
  NavigationService,
} from '@ev-portals/cp/frontend/shared/util';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

import { FederationService } from './federation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  #authApiService = inject(AuthenticationApiService);
  #userInfoApiService = inject(UserInformationApiService);
  #federationService = inject(FederationService);
  #analyticsService = inject(AnalyticsService);
  #cookieService = inject(CookieService);
  #globalDataService = inject(GlobalDataService);
  #navigationService = inject(NavigationService);
  #feedbackMessageService = inject(FeedbackMessageService);

  #_user$ = new ReplaySubject<UserBasicInfoDto | null>(1);
  // Save the current state of the user
  #user: UserBasicInfoDto | null = null;
  user$ = this.#_user$.asObservable().pipe(shareReplay(1));
  isLoggedIn$: Observable<boolean> = this.#getIsLoggedIn$();
  loggedInUser$: Observable<UserBasicInfoDto> = this.isLoggedIn$.pipe(
    filter(Boolean),
    switchMap(() => this.user$ as Observable<UserBasicInfoDto>),
  );
  hasSalesRole$: Observable<boolean | undefined> = this.user$.pipe(
    map(this.hasSalesRole),
    distinctUntilChanged(),
  );
  hasSelfServiceRole$: Observable<boolean | undefined> = this.user$.pipe(
    map(this.hasSelfServiceRole),
    distinctUntilChanged(),
  );

  logoutSuccess$ = this.#federationService.logoutSuccess$;
  logoutHandler = {
    next: (): void => {
      this.#federationService.initLogout();
      this.#initLocalUser(null);
      this.#navigationService.navigateToHome();
    },
    error: (err: Error): void => {
      console.log('Error: Logout Unsuccessful', err);
    },
  };

  $impersonationMode = signal<boolean>(false);

  hasCheckoutPermission$: Observable<boolean> = this.hasPermission(PermissionsEnum.Checkout);

  permissionMap$: Observable<Partial<Record<PermissionsEnum, boolean>>> = this.user$.pipe(
    map(user => Object.fromEntries(user?.permissions.map(permission => [permission, true]) ?? [])),
    distinctUntilChanged(),
  );
  $permissionMap = toSignal(this.permissionMap$);

  constructor() {
    this.loadUser().subscribe();
  }

  loadUser(): Observable<UserBasicInfoResponseDto> {
    return this.#userInfoApiService.getBasicUserInfo().pipe(
      catchError((res: HttpErrorResponse, caught$) => {
        console.error(res.error);
        this.#initLocalUser(null);
        this.#feedbackMessageService.showErrorMessage(
          'Error while loading user information. Please log in again',
          2000,
        );

        return caught$;
      }),
      tap(res => {
        this.#initLocalUser(res.user);
      }),
    );
  }

  hasPermission(permission: PermissionsEnum): Observable<boolean> {
    return this.user$.pipe(
      map(user => user?.permissions.includes(permission) ?? false),
      distinctUntilChanged(),
    );
  }

  patchProductSearchQueryString(queryString: string): Observable<UserBasicInfoResponseDto> {
    this.#patchLocalUser({ productSearchQueryString: queryString });

    return this.#userInfoApiService.patchUser({
      body: { productSearchQueryString: queryString },
    });
  }

  patchTours(tours: PatchToursDto): Observable<UserBasicInfoResponseDto> {
    this.#patchLocalUser({ tours });

    return this.#userInfoApiService.patchUser({
      body: {
        tours,
      },
    });
  }

  login(authorizationCode: string, threeIamId: string | undefined): Observable<UserBasicInfoDto> {
    this.#analyticsService.trackEvent('auth', 'login');

    return this.#authApiService
      .login({
        body: { authorizationCode, threeIamId },
      })
      .pipe(
        tap(userInfo => {
          this.#initLocalUser(userInfo);
        }),
      );
  }

  /**
   * Logout can be triggered by
   *  - the user explicitly
   *  - or by the authInterceptor, if any request gives 401 back - except the /me endpoint, which is allowed to give 401 back
   */
  logout(): Observable<void> {
    this.#analyticsService.trackEvent('auth', 'logout');
    this.#analyticsService.clearData();
    this.#globalDataService.clearGlobalData();

    return this.#authApiService.logout();
  }

  #patchLocalUser(partialUser: Partial<UserBasicInfoDto>): void {
    if (this.#user) {
      this.#updateLocalUser({
        ...this.#user,
        ...partialUser,
      });
    }
  }

  // Happens on login & logout
  #initLocalUser(user: UserBasicInfoDto | null): void {
    this.#checkImpersonationMode(user);

    this.#analyticsService.initCustomer(user?.sbuInformation.join(',') ?? undefined);

    this.#globalDataService.patchGlobalData({
      userEmail: user?.email,
      isInternalUser: !!user?.isInternalUser,
    });

    this.#updateLocalUser(user);
  }

  #updateLocalUser(user: UserBasicInfoDto | null): void {
    this.#_user$.next(user);
    this.#user = user;
  }

  // Detect impersonation mode on user changes
  #checkImpersonationMode(user: UserBasicInfoDto | null): void {
    if (user && this.#cookieService.getImpersonationToken()) {
      this.$impersonationMode.set(true);
    } else {
      this.$impersonationMode.set(false);
    }
  }

  hasSalesRole(user: UserBasicInfoDto | null): boolean | undefined {
    return user?.roles.includes('SALES_USER');
  }

  hasSelfServiceRole(user: UserBasicInfoDto | null): boolean | undefined {
    return user?.roles.includes('SELF_SERVICE_USER');
  }

  #getIsLoggedIn$(): Observable<boolean> {
    return this.user$.pipe(
      map((currentUser: UserBasicInfoDto | null) => {
        return !!currentUser;
      }),
      distinctUntilChanged(),
    );
  }
}
