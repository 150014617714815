import { environment } from '../../environments/environment';

export class ScriptInjector {
  static WINDOW = window as any;

  public static injectTealiumScript(): void {
    // Disable auto-tracking in Piano Analytics - based on the docs
    this.WINDOW.utag_cfg_ovrd = this.WINDOW.utag_cfg_ovrd || {};
    this.WINDOW.utag_cfg_ovrd.noview = true;

    // It can be dev/qa/prod
    const env = ['qa', 'prod'].includes(environment.environment) ? environment.environment : 'dev';

    // Prepare script
    const scriptContent = `
      var utag_data = {};

      (function(a,b,c,d){
        a='https://tags.tiqcdn.com/utag/basf/basfshop/${env}/utag.js';
        b=document;
        c='script';
        d=b.createElement(c);
        d.src=a;
        d.type='text/java'+c;
        d.async=true;
        a=b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d,a);
      })();`;

    // Inject
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.id = 'tracking-script';
    scriptTag.textContent = scriptContent;
    document.head.appendChild(scriptTag);
  }

  public static injectNpsScript(): void {
    console.log('Injecting NPS script...');
    // Inject script with src
    const scriptTagSrc = document.createElement('script');
    scriptTagSrc.type = 'text/javascript';
    scriptTagSrc.id = 'nps-script';
    scriptTagSrc.src = 'https://resources.digital-cloud.medallia.eu/wdceu/421620/onsite/embed.js';
    scriptTagSrc.async = true;
    document.head.appendChild(scriptTagSrc);

    scriptTagSrc.onload = () => {
      console.log('NPS script loaded successfully.');
      this.injectUpdatePageViewScript();
    };
  }

  public static injectUpdatePageViewScript(): void {
    // Inject script with textContent
    const scriptTagContent = document.createElement('script');
    scriptTagContent.type = 'text/javascript';
    scriptTagContent.id = 'update-page-view-script';
    scriptTagContent.async = true;
    scriptTagContent.textContent = `
      if (window.KAMPYLE_ONSITE_SDK &&
            typeof window.KAMPYLE_ONSITE_SDK.updatePageView === 'function' &&
            !!window.globalData.userEmail) {
        window.KAMPYLE_ONSITE_SDK.updatePageView();
        console.log('updatePageView executed!');
      }
    `;

    document.head.appendChild(scriptTagContent);
  }
}
